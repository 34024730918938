<template>
  <div class="maintain">
    <div class="tabs-body">
      <y-tabs :label-title="labelTitle" @switch-click="switchAction"
        :index="tabIndex">
        <div slot="content" v-for="(item, index) in labelTitle"
          :key="index">
          <!---金豆详情--->
          <div class="gold-details" v-if="index === 0"
            v-show="currentPage === 0"
            id="goldDetails">
            <div class="goldContent">
              <gold-details :gold-num="goldNum" />
            </div>
            <div class="goods">
              <div class="goodsContent">
                <div class="goodsListNew" v-for="(item, index) in goodsList"
                  :class="{ 'noRestGoodList': !item.goodsStock }"
                  :key="`goods_${item.goodsId}_${index}`"
                  @click="jumpDetails(item)">
                  <div class="goodsListImgNew">
                    <div class="exchanged" v-if="item.goodsStock <= 0">
                      <img
      src="//download-cos.yofish.com/yofish-gongjushiyebu/20230329142003440-exchanged.png" alt="" />
                    </div>
                    <!-- <img v-if="isGoodsId(item.goodsId)"
                      :src="require(`./img/${item.goodsId}.png`)" alt="" /> -->
                    <img v-if="item.goodsPicUrl" :src="item.goodsPicUrl" alt="" />
                    <img v-else
      src=" //download-cos.yofish.com/yofish-gongjushiyebu/20230308091644618-exchange-empty.png"
                    alt="" />
                  </div>
                  <div class="goodsListTextNew">
                    <div class="goodsListTextTitle">{{ item.goodsName }}</div>
                    <div class="gold-list-label">
                      <div class="sign" v-if="item.exchangeType === '1'">不包邮</div>
                      <div class="sign" v-else>包邮</div>
                    </div>
                    <div class="goodsListTextDetail">
                      <span class="needNum">
                        <span class="bold">{{ item.goldPrice }}</span>
                        金豆
                      </span>
                      <span class="oldPriceNew">{{ item.subTitle }}</span>
                    </div>
                  </div>
                  <!-- <div class="noRestNew">
                    <img src="./img/noRest.png" alt="">
                  </div> -->
                </div>
              </div>
            </div>
            <div class="swiper-scrollbar"></div>
          </div>
          <!---金豆抽奖--->
          <div class="gold-draw" v-if="index === 1"
            v-show="currentPage === 1">
            <y-draw :current-page="currentPage" :cuserId="cuserId"
            :gold-num="goldNum"
            @deduct-gold="getGoldNums"></y-draw>
          </div>
        </div>
      </y-tabs>
    </div>
    <y-loading v-show="isLoading" />
  </div>
</template>
<script>
import { getAppStatus, callAppHandler } from '@/utils/app';
import { jsJumpRouter } from '@/utils/bridge';
import YTabs from '@/components/tab';
import YDraw from './draw';
import goldDetails from './components/goldDetails';
import YLoading from '@/components/loading';
import { goldmallGoodsListApi, getGoldNumsApi } from '@/api/base1';
import { goodsIdList } from './js/goods';
import { getScrollTop, getScrollHeight, getWindowHeight } from '@/utils';

export default {
  name: 'GoldEden',
  components: {
    YTabs, YDraw, goldDetails, YLoading,
  },
  data() {
    return {
      currentPage: 0,
      goldNum: 0,
      goodsList: [],
      pageNum: 1,
      totalPage: 0, // 总页数
      isLoading: false,
    };
  },
  created() {
    this.currentPage = Number(this.tabIndex);
    document.documentElement.scrollTop = 0;
    const { currentPage } = this;
    this.labelTitle[currentPage].isActive = true;
  },
  mounted() {
    document.title = '金豆乐园';
    this.getGoldNums();
    // document.body.style.overflow = 'hidden';
    this.getGoods();
  },
  computed: {
    labelTitle() {
      return [{ name: '金豆兑换' }, { name: '金豆抽奖' }];
    },
    cuserId() {
      // return '3129b4b2-a736-4e9b-97d8-7a8ee7f357fe';
      // return '6d1455cb-cdbb-46c4-911d-8a60a0342fe9';
      return getAppStatus().cuserId;
    },
    isIos() {
      return /i(os|phone|pad)/i.test(navigator.userAgent);
    },
    tabIndex() {
      return this.$route.query.tabIndex || 0;
    },
    pageSize() {
      return 20;
    },
  },
  methods: {
    async getGoods() {
      let res;
      const { cuserId, pageNum, pageSize } = this;
      this.isLoading = true;
      try {
        res = await goldmallGoodsListApi({ cuserId, currentPage: pageNum, pageSize });
      } catch (e) {
        console.log(e);
        this.$toast({ content: e?.desc || '网络异常' });
      }
      this.isLoading = false;
      if (res?.code === 1) {
        const { results, pageCount } = res;
        const { pageSize } = this;
        // this.goldNum = results.availableGoldAmount;
        const goodsList = results || [];
        let newGoodsList = goodsList.filter((item) => item.goodsType === '3');
        this.goodsList = this.goodsList.concat(newGoodsList); // 有鱼定制专区
        this.totalPage = Math.ceil(pageCount / pageSize);
        this.$nextTick((_) => {
          this.scrollAction();
        });
        // if (isIos) {
        //   this.goodsList = this.goodsList.filter((item) => !item.goodsId.includes('FU-YG-E-'));
        // }
      }
    },
    getGoldNums() {
      getGoldNumsApi({ cuserId: this.cuserId }).then((res) => {
        if (res?.code === 1) {
          this.goldNum = res?.results || 0;
        }
      });
    },
    switchAction(index) { // 切换tab页
      this.currentPage = index;
    },
    jumpDetails(data) {
      const { goodsId, goodsStock } = data;
      this.$router.push({
        path: 'details',
        query: { goodsId },
      });
    },
    goRecord() {
      this.$router.push({
        path: 'record',
      });
    },
    isGoodsId(id) {
      return goodsIdList().includes(id);
    },
    jumpRouter() {
      return jsJumpRouter('yyjz://app/goldDetai');
    },
    scrollAction() {
      window.onscroll = () => {
        if (this.pageNum >= this.totalPage) return false;
        // console.log(getScrollTop(), getWindowHeight(), getScrollHeight());
        if (getScrollTop() + getWindowHeight() >= getScrollHeight() - 5) {
          this.pageNum++;
          this.getGoods();
        }
      };
    },
  },
  beforeDestroy() {
    document.body.style.overflow = '';
    document.documentElement.scrollTop = 0;
  },
};
</script>
<style scoped lang="scss">
  @import './scss/goods';
  /deep/ .y-tabs_titles {
    background-color: #fff;
  }
  .maintain, .maintain-page {
    width: 100%;
    height: 100%;
  }
  .maintain-page {
    background: #fff;
    .image {
      position: relative;
      .bg {
        position: absolute;
        width: 100%;
        height: 80px;
        background: #fff;
        left: 0;
        bottom: 0;
      }
    }
    img {
      width: 100%;
      margin-top: 120px;
    }
    .tips-text {
      font-size: 30px;
      color: rgba(0, 0, 0, .6);
      margin: 40px 62px;
    }
  }
  .rule-btn {
    font-size: .20666rem;
    color: #000;
    text-decoration: none;
    float: right;
    padding: 5px 10px;
    border: 1PX solid #333;
    border-radius: 20px;
    position: relative;
    top: -4px;
  }
</style>
