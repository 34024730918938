<template>
  <div class="gold-details-wrap">
    <div class="gold-details-frame">
      <div class="rule-btn" @click="jumpRule">
        <span>金豆规则</span>
        <img src="@/assets/images/arrow.png" alt="" />
      </div>
      <p class="gold-num">{{ goldNum }}</p>
      <p class="gold-desc">可用金豆</p>
      <div class="gold-group">
        <div class="list" @click="jumpAction('exchange-record')">金豆记录</div>
        <div class="list" @click="jumpAction('gold-record')">兑换记录</div>
      </div>
    </div>
    <div class="text-btn" @click="jumpAppGoldDetails">金豆不够？去赚金豆</div>
  </div>
</template>
<script>
import { jsClosePage, jsJumpGoldRecord } from '@/utils/bridge';

export default {
  name: 'GoldDetails',
  props: {
    goldNum: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    jumpAppGoldDetails() { // 关掉当前页面
      jsClosePage();
    },
    jumpAction(type) {
      if (type === 'gold-record') return this.$router.push({ path: 'record' });
      if (type === 'exchange-record') return jsJumpGoldRecord();
    },
    jumpRule() {
      this.$router.push({ path: '/gold/rule' });
    },
  },
};
</script>
<style lang="scss" scoped>
.gold-details-wrap {
  width: 100%;
  .gold-details-frame {
    position: relative;
    padding: 20px 0;
    background: #FFFFFF;
    border-radius: 16px;
    text-align: center;
    .gold-num {
      font-size: 40px;
      font-weight: 500;
      color: #333333;
      line-height: 56px;
    }
    .gold-desc {
      font-size: 20px;
      font-weight: 400;
      color: rgba(51,51,51,0.6);
      line-height: 28px;
      margin-top: 8px;
    }
    .gold-group {
      display: flex;
      margin-top: 40px;
      .list {
        position: relative;
        flex: 1;
        font-size: 24px;
        font-weight: 400;
        color: #333333;
        line-height: 34px;
        &:first-child {
          &::after {
            content: '';
            position: absolute;
            width: 2px;
            height: 28px;
            background-color: rgba(0,0,0,0.15);
            top: -1px;
            right: -1px;
          }
        }
      }
    }
  }
  .text-btn {
    font-size: 24px;
    font-weight: 400;
    color: rgba(51,51,51,0.6);
    line-height: 34px;
    margin-top: 40px;
    text-decoration: underline;
    text-align: center;
  }
  .rule-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    color: #333333;
    line-height: 28px;
    img {
      width: 20px;
      margin-left: 8px;
      transform: rotate(-90deg);
    }
  }
}
</style>
