<template>
  <div class="page-container">
    <div class="draw-page image-common">
      <div class="draw-title">
        <img
    src="//download-cos.yofish.com/yofish-gongjushiyebu/20230307141930745-gold-title.png" alt="" />
      </div>
      <div class="draw-box">
        <img
      src="//download-cos.yofish.com/yofish-gongjushiyebu/20230307142429628-gold-box.png" alt="" />
      </div>
      <div class="draw-btn animate" @click="disBlindBoxAction">
        <img
    src="//download-cos.yofish.com/yofish-gongjushiyebu/20230307142631561-lottery-btn.png" alt="" />
      </div>
      <p class="draw-desc">消耗1金豆抽奖一次</p>
      <div class="draw-btn-groups">
        <div class="btn" @click="btnClickAction('exchange')">
          <span>我的奖品</span>
        </div>
        <div class="btn" @click="btnClickAction('prize')">
          <span>奖池</span>
        </div>
        <div class="btn" @click="btnClickAction()">
          <span>规则</span>
        </div>
      </div>
      <y-dialog :visible="visible" class="packet-dialog">
        <div class="packet-box">
          <div class="prize-image">
            <img :src="prizeData.imageUrl" alt="" />
          </div>
          <p class="prize-name">{{ prizeData.name }}</p>
          <p class="prize-intro">{{ prizeData.desc }}</p>
          <div class="packet-btn" @click="closeVisible">确认领取</div>
        </div>
      </y-dialog>
      <y-loading v-show="isLoading"/>
      <y-actionsheet :is-visible="ruleVisible"
        :isLockBgScroll="true"
        @close="ruleVisible = false">
        <span slot="sTitle">活动规则</span>
        <div slot="custom">
          <gold-rule />
        </div>
      </y-actionsheet>
    </div>
  </div>
</template>
<script>
import { openMyBlindboxApi, getBlindboxActivityInfoApi } from '@/api/base1';
import { debounce } from '@/utils';
import { jsBriageEvent } from '@/utils/bridge';

import YDialog from '@/components/dialog';
import YLoading from '@/components/loading';
import YActionsheet from '@/components/actionsheet';
import GoldRule from '../components/goldRule';

export default {
  name: 'Draw',
  data() {
    return {
      visible: false,
      prizeData: {},
      isLoading: false,
      ruleVisible: false,
      prizeList: [],
    };
  },
  components: {
    YDialog,
    YLoading,
    YActionsheet,
    GoldRule,
  },
  props: {
    cuserId: {
      type: String,
      default: '',
    },
    goldNum: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: [Number, String],
      default: 0,
    },
  },
  mounted() {},
  watch: {
    currentPage: {
      handler(val) {
        if (val) {
          console.log('currentPage');
          this.blindMonitor();
          let { prizeList } = this;
          val === 1 && !prizeList.length && this.initData();
        }
      },
      immediate: true,
    },
  },
  computed: {
    activityId() {
      return 9;
    },
    businessType() {
      return 0;
    },
  },
  methods: {
    blindMonitor() {
      window?._monitor('init', {
        businessType: this.businessType,
        businessId: this.activityId,
        cuserId: this.cuserId,
      });
      window._monitor('pageset', 'jdcjym_imp', {
        eventName: '金豆抽奖页面曝光',
      });
      jsBriageEvent({ eventId: 'jdcjym_imp' });
    },
    async initData() {
      let res;
      const { cuserId, activityId } = this;
      try {
        res = await getBlindboxActivityInfoApi({ cuserId, activityId });
      } catch (e) {
        console.log(e);
        return this.$toast('服务器开小差了，请稍后再试~');
      }
      if (res?.code === 1) {
        this.prizeList = res?.results?.prizePool?.prizeList || [];
      }
    },
    disBlindBoxAction: debounce(async function (data) {
      let res;
      const {
        cuserId,
        activityId,
        goldNum,
        prizeList,
      } = this;
      window?._monitor('track', 'jdcjym_ljcjan_click', {
        eventName: '金豆抽奖页面-立即抽奖按钮点击',
      });
      jsBriageEvent({ eventId: 'jdcjym_ljcjan_click' });
      if (!goldNum) return this.$toast('金豆不足，无法抽奖！');
      if (!prizeList.length) return this.$toast('网络异常，请关闭页面重新进入');
      this.isLoading = true;
      if (this.disabled) return false;
      this.disabled = true;
      try {
        res = await openMyBlindboxApi({ cuserId, activityId });
      } catch (e) {
        console.log(e);
        this.disabled = false;
        this.isLoading = false;
        let codeArr = [-2026, -9002, -2023];
        if (e?.code === -2026 || e?.code === -9002) {
          return this.$toast({ content: '活动更新维护中...' });
        }
        return this.$toast({ content: e?.desc || '网络异常' });
      }
      this.isLoading = false;
      this.disabled = false;
      if (res?.code === 1) {
        this.$emit('deduct-gold');
        this.getPrizeData(res?.results || '');
      }
    }, 1000, true),
    getPrizeData(id) {
      const { prizeList } = this;
      let prizeData = {};
      if (prizeList.length) {
        prizeData = prizeList.find((item) => item.id === id);
      }
      this.prizeData = prizeData;
      if (prizeData) {
        jsBriageEvent({ eventId: 'jdcjym_zjtc_imp', events: { product_name: prizeData.name } });
        this.visible = true;
      }
    },
    btnClickAction(type) {
      if (type) {
        return this.$router.push(`/gold/draw/${type}`);
      }
      this.ruleVisible = true;
    },
    closeVisible() {
      this.visible = false;
      this.prizeData = {};
    },
  },
};
</script>
<style scoped lang="scss">
  @import '../scss/draw';
</style>
