<template>
  <div class="y-tabs">
    <div class="y-tabs_titles">
      <div class="item-text"
        v-for="(item, index) in label" :key="index"
        :class="{ active: item.isActive }"
        @click="switchAction(item, $event, index)">
        {{ item.name }}
      </div>
      <div class="line"
        ref="line"
        :style="{
        left: lineLeft }"></div>
    </div>
    <div class="y-tabs-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'YTab',
  data() {
    return {
      lineLeft: 0,
      label: [],
    };
  },
  props: {
    labelTitle: {
      type: Array,
      default: () => [],
    },
    index: {
      type: [Number, String],
      default: 0,
    },
  },
  created() {
    this.label = this.labelTitle;
  },
  watch: {
    index(val) {
      this.elementStyle(val);
    },
  },
  mounted() {
    this.$nextTick((_) => {
      this.elementStyle(this.index);
    });
  },
  methods: {
    round(val) {
      return Math.round(val);
    },
    switchAction(item, e, index) {
      const { lineW } = this;
      this.label.forEach((item) => {
        this.$set(item, 'isActive', false);
      });
      this.$set(item, 'isActive', true);
      // this.lineLeft = e.target.offsetLeft - (lineW / 2) + 'px';
      this.elementStyle(index);
      this.$emit('switch-click', index);
    },
    elementStyle(index = 0) {
      const lineEle = this.$refs.line;
      this.lineW = lineEle.clientWidth;
      this.linePW = lineEle.parentNode.clientWidth;
      const { labelTitle, lineW, linePW } = this;
      this.lineLeft = (linePW / (labelTitle.length * 2) - lineW / 2) + (linePW / (labelTitle.length)) * index + 'px';
    },
  },
};
</script>
<style scoped lang="scss">
  .y-tabs {
    .y-tabs_titles {
      display: flex;
      position: relative;
      // box-shadow: 0 -3px 10px 3px rgba(212, 223, 225, .6);
      .line {
        position: absolute;
        transition: all 300ms ease-in-out;
        bottom: 0;
        width: 80px;
        height: 8px;
        background: #EF5C53;
        border-radius: 4px;
      }
    }
  }
  .item-text {
    text-align: center;
    flex: 1 0 auto;
    font-size: 32px;
    color: rgba(51,51,51,0.6);
    line-height: 44px;
    padding: 22px 0;
    // font-size: 0.373333rem;
    // line-height: 1.066666rem;
    font-weight: 400;
    color: rgba(51,51,51,0.6);
    &.active {
      font-weight: 500;
      color: #333333;
    }
  }
</style>
