<template>
  <div class="c">
    <p>1、每颗金豆能完成一次抽奖，抽完之后金豆会被回收。</p>
    <p>2、商品一经兑换，一律不予退还，请用户兑换前仔细参照使用规则等信息。</p>
    <p>3、会员优惠券奖品：用户领取后需在有效期内使用，超过有效期未兑换/使用的，视为用户主动放弃该奖品。会员优惠券可在“我的奖品”中查看。</p>
    <p>4、权益类奖品：用户中奖后，用户需手动兑换，领取后3个工作日内到账。权益类产品超过有效期未兑换/使用的，视为用户主动放弃该奖品。</p>
    <p>5、实物奖品，用户中奖后，需要在活动期间自主填写手机号码和收货地址，我们会在14个工作日内进行发放。
      因用户自身原因未填写领取信息或填写错误不全的领取信息，导致无法兑换或超过兑换时间以及中奖用户本人明确表示放弃领奖，本次中奖资格将作废，礼品以实物为准。</p>
    <p>6、奖池内的奖品会不定期的进行更换。</p>
    <p>7、保底返还类奖品会直接计入相应的库存中，不需要领取。（如金豆，记账树成长天数等）</p>
    <p>8、通过非法途径获得金豆后进行的兑换，或不按本站使用规则进行兑换，本站有权不提供服务。</p>
    <p>9、凡以不正当手段(包括但不限于作弊、扰乱系统、实施网络攻击等)进行兑换，平台有权终止您的兑换权利。</p>
    <p>兑换专区商品最终解释权归有鱼记账所有，如有疑问请扫码二维码联系客服。</p>
    <p>
      <img alt="Image" src="//download-cos.yofish.com/youyu-gongjushiyebu/20230313181317415-ewm.png"
        width="230" height="230" />
    <br />
    </p>
  </div>
</template>
<script>
export default {
  name: 'PackageRule',
};
</script>
<style lang="scss" scoped>
  p {
    font-size: 26px;
    line-height: 42px;
  }
</style>
