export function yinggeChannel() { // 配置印鸽channel
  return {
    'FU-YG-E-1012': 'yyjz-shycdx', // DIY三合一伸缩充电线
    'FU-YG-E-1013': 'yyjz-mkb', // DIY创意马克杯

    'FU-YG-E-1014': 'yyjz-drlydsb', // 大容量双层运动水杯
    'FU-YG-E-1016': 'yyjz-xxzmjsq', // 小型桌面加湿器
    'FU-YG-E-1017': 'yyjz-tmsnb', // 定制ins风透明收纳包
    'FU-YG-E-1018': 'yyjz-xkfbxb', // 潮酷斜挎帆布小包
    'FU-YG-E-1019': 'yyjz-dhsbd', // DIY定制大号鼠标垫
    'FU-YG-E-1020': 'yyjz-jywkzsh', // DIY定制装饰画
    'FU-YG-E-1021': 'yyjz-gxdzjdtz', // 个性定制指甲刀套装
    'FU-YG-E-1022': 'yyjz-sl', // 时尚百搭手链
    'FU-YG-E-1023': 'yyjz-hzjbt', // 定制可收纳化妆镜摆台
    'FU-YG-E-1024': 'csczsl', // 彩色串珠手链
    'FU-YG-E-1025': 'yyjz-xhlggshsnh', // 小号六宫格首饰收纳盒
    'FU-YG-E-1026': 'yyjz-fzlz', // 氛围感仿真蜡烛灯
    'FU-YG-E-1027': 'yyjz-diyzjh', // DIY桌面纸巾盒
    'FU-YG-E-1028': 'yyjz-zdsjzj', // 伸缩折叠手机支架
    'FU-YG-E-1029': 'yyjz-tputmsjrk', // TPU透明手机软壳定制
    'FU-YG-E-1030': 'yyjz-qldzsjq', // 情人节水晶球
    'FU-YG-E-1031': 'yyjz-qlxkbt', // 情侣相框摆台
  };
}

export function goodsIdList() { // 商品ID
  return [
    'YG-1007', 'YG-1008', 'YG-1009', 'YG-1010', 'YG-1011',
    'FU-YG-E-1012', 'FU-YG-E-1013', 'FU-YG-E-1014', 'FU-YG-E-1016',
    'FU-YG-E-1017', 'FU-YG-E-1018', 'FU-YG-E-1019', 'FU-YG-E-1020',
    'FU-YG-E-1021', 'FU-YG-E-1022', 'FU-YG-E-1023', 'FU-YG-E-1024',
    'FU-YG-E-1025', 'FU-YG-E-1026', 'FU-YG-E-1027', 'FU-YG-E-1028',
    'FU-YG-E-1029', 'FU-YG-E-1030', 'FU-YG-E-1031',
  ];
}
